<template>
  <p class="text-black mt-3 mb-4">
    <b>Preview</b> - Google will optimize combinations of headlines and descriptions.
<!--    <button class="btn btn-info btn-sm float-right"
            @click="$refs.reviewerInsightsModal.show">Reviewer Insights</button>-->
  </p>

  <preview :formData="form" />

  <form>
    <div class="card">
      <div class="card-body p-3">
        <div class="row keyword-tips mb-2">
          <h3 class="my-3">How to create a good Google ad?</h3>
          <ul>
            <li>First, choose a descriptive product name. This will always be visible (e.g “Travel Yoga Mat”):</li>
          </ul>
          <div class="form-group">
            <input :class="['form-control', {'is-invalid': errorFor(form.headingInput[0])}]"
                  :disabled="loading"
                  v-model="form.headingInput[0].value"
                  placeholder="Product name (required)"
                  type="text">

            <p :class="['text-right m-0', {'text-danger': form.headingInput[0].value.length > 30}]">{{ form.headingInput[0].value.length }} / 30</p>
            <v-errors :errors="errorFor(form.headingInput[0].value)" />
          </div>
        </div>

        <div class="row keyword-tips mb-2">
          <ul>
            <li>Second, enter at least 2 of your product’s main features and benefits. You can add more, and google will find the best combination.</li>
            <li>E.g. “Lightweight and portable”, “2.5mm thin”,  “400+ 5-star reviews on Amazon”, “Prime Eligible/Free shipping with Prime”</li>
          </ul>
        </div>

        <div class="form-group mb-0" v-if="form.headingInput.length > 0">
          <!-- <label class="text-black font-weight-bold mb-2">Enter a short descriptive product name (always shown) <span class="text-danger">*</span></label> -->
          <div v-for="(data, i) in form.headingInput.slice(1)" :key="i">
            <div class="input-group mb-2">
              <input :class="['form-control', {'is-invalid': errorFor(form.headingInput[i])}]"
                     :disabled="loading"
                     v-model="data.value"
                     :placeholder="data.placeholder"
                     type="text">
              <button class="btn btn-outline-dark ai-button"
                      :disabled="loading"
                      @click.prevent="getHeadlines(i+1)">
                <img src="/assets/images/ai.png"
                     style="height: 25px" />
              </button>
            </div>

            <p :class="['text-right m-0', {'text-danger': data.value.length > 30}]">{{ data.value.length }} / 30</p>
            <v-errors :errors="errorFor(data.value)" />
          </div>
        </div>  

        <div class="text-right mt-2">
          <button type="button" class="btn btn-primary custom-btn mr-2" @click="addTextarea('headingInput')" v-if="form.headingInput.length < 13">
            <i class="fa-solid fa-plus"></i> Add more
          </button>
          <button type="button" class="btn btn-danger custom-btn" @click="removeTextarea('headingInput')" v-if="form.headingInput.length > 3">
            <i class="fa-solid fa-xmark"></i> Remove
          </button>
        </div>

        <div class="row keyword-tips mb-1 mt-2">
          <!-- <h3 class="my-3">How to create a good Google ad?</h3> -->
          <ul>
            <li>Enter at least two descriptions with additional details/value of your product.</li>
            <li>First description will always show, other descriptions may or may not show, so important information should go first.</li>
          </ul>
        </div>

        <div class="form-group mb-0">
          <!-- <label class="text-black font-weight-bold">Enter descriptions that detail the value and benefits of your product or offer</label> -->
          <div class="mb-2" v-for="(data, i) in form.descriptionInput" :key="i">
            <div class="input-group mb-2">
            <textarea :class="['form-control', {'is-invalid': errorFor(data.value)}]"
                      v-model="data.value"
                      :disabled="loading"
                      :placeholder="data.placeholder"
                      rows="3" />

              <button class="btn btn-outline-dark ai-button"
                      :disabled="loading"
                      @click.prevent="getDescription(i)">
                <img src="/assets/images/ai.png" style="height: 25px" />
              </button>
            </div>
            <p :class="['text-right m-0', {'text-danger': data.value.length > 90}]">{{ data.value.length }} / 90</p>
            <v-errors :errors="errorFor(data.value)" />
          </div>
          <div class="text-right">
            <button type="button" class="btn btn-primary custom-btn mr-2" @click="addTextarea('descriptionInput')" v-if="form.descriptionInput.length < 4">
              <i class="fa-solid fa-plus"></i>  Add more
            </button>
            <button type="button" class="btn btn-danger custom-btn" @click="removeTextarea('descriptionInput')" v-if="form.descriptionInput.length > 2">
              <i class="fa-solid fa-xmark"></i> Remove
            </button>
          </div>

          <!-- <div>
            <textarea :class="['form-control', {'is-invalid': errorFor('description2')}]"
                      v-model="form.description2"
                      placeholder="Required - Other specific benefits or calls-to-action"
                      rows="3" />

            <p class="text-right m-0">{{ form.description2.length }} / 90</p>
            <v-errors :errors="errorFor('description2')" />
          </div> -->

        </div>

      </div>
    </div>
  </form>

  <reviewer-insights-modal ref="reviewerInsightsModal" />
</template>

<script>
import Preview from "@/views/create_campaign/google_wizard/Preview";
import ReviewerInsightsModal from "@/views/create_campaign/google_wizard/ReviewerInsightsModal.vue";
import {mapGetters} from "vuex";
export default {
  components: {Preview, ReviewerInsightsModal},
  props: ['formData', 'errors'],
  data() {
    return {
      loading: false,
      headlines: [],
      descriptions: [],
      adAccounts: [],
      form: {},
    }
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct',
    }),
    adPreview() {
      return {
        header: (this.form.headingInput ? this.form.headingInput : []),
        description: (this.form.descriptionInput ? this.form.descriptionInput : [])
      }
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  methods: {
    async getHeadlines(i) {
      this.loading = true;

      if (!this.headlines.length) {
        await this.generateHeadlines();
      }

      if (this.headlines.length) {
        this.form.headingInput[i].value = this.headlines[0];
        this.headlines.shift();
      }

      this.loading = false;
    },
    async getDescription(i) {
      this.loading = true;

      if (!this.descriptions.length) {
        await this.generateDescriptions();
      }

      if (this.descriptions.length) {
        this.form.descriptionInput[i].value = this.descriptions[0];
        this.descriptions.shift();
      }

      this.loading = false;
    },
    async generateHeadlines() {
      const response = await this.axios.post('/google/ai/headlines', {
        title: this.amazonProduct.title,
        features: this.amazonProduct.feature_bullets
      });

      this.headlines = response.data.data;
    },
    async generateDescriptions() {
      const response = await this.axios.post('/google/ai/descriptions', {
        title: this.amazonProduct.title,
        features: this.amazonProduct.feature_bullets
      });

      this.descriptions = response.data.data;
    },
    addTextarea(type) {
      if(type == 'headingInput') {
        this.form.headingInput.push({
          value: '',
          placeholder: 'Additional feature/benefit (optional)',
        });
      } else {
        this.form.descriptionInput.push({
          value: '',
          placeholder: 'Product Description (optional)',
        });
      }
    },

    removeTextarea(type) {
      if(type == 'headingInput') {
        this.form.headingInput.pop();
      } else {
        this.form.descriptionInput.pop();
      }
    }

  }
}
</script>

<style scoped>
.ai-button:hover,
.ai-button:focus,
.ai-button:active {
  color: inherit; /* Retain original text color */
  background-color: inherit; /* Retain original background color */
  border-color: inherit; /* Retain original border color */
  text-decoration: none; /* Remove any text decoration changes */
  outline: none; /* Remove focus outline */
  box-shadow: none; /* Remove focus shadow */
}

.row.keyword-tips {
    display: block;
    padding: 0px 20px;
}
.keyword-tips h3{
  font-size: 18px;
  color: #313131;
  font-weight: 600;
}

.custom-btn{
  padding: 5px 10px;
}

.keyword-tips ul li {
    color: #313131ad;
    font-size: 18px;
    line-height: 30px;
}
</style>