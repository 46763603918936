<template>
  <div class="modal fade" id="google-keywords-idea-modal" tabindex="-1" role="dialog" aria-labelledby="basicDemoTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select Keywords</h5>
        </div>
        <div class="modal-body">

          <!-- <div class="row mb-2">
            <div class="col-12 col-md-6">
              <button type="button" class="btn btn-md btn-success w-100" @click="duplicateAmazon()">Duplicate & Add “amazon” to all keywords</button>
            </div>
            <div class="col-12 col-md-6">
              <button type="button" class="btn btn-md btn-primary w-100" @click="duplicateOnAmazon()">Duplicate & Add “on amazon” to all keywords</button>
            </div>
            <div class="col-12 keyword-tips">
              <ul>
                <li>It is often a good choice to add “amazon” and/or “on amazon” to keywords. For example: “Yoga mat on amazon” or “Garlic press amazon”</li>
                <li>If you want, we can do it for you while keeping the original keywords.</li>
                <li>We would only duplicate and add keywords if the phrase “amazon” or “on amazon” doesn’t already exist in them.</li>
              </ul>
            </div>
          </div> -->

          <div class="row mb-3" v-if="currentKeywords.length > 0">
            <div class="col-12 mb-2">
              <span class="badge badge-dark mr-2 mb-2"
                    v-for="(key, i) in currentKeywords"
                    :key="'key_'+i">{{ key }}
                <a href="#"
                  @click.prevent="deleteKeyword(key)"
                  class="text-danger">X</a></span>

<!--              <span class="text-danger" v-if="currentKeywords.length < 5"> ({{ 5 - currentKeywords.length }} more required)</span>-->
            </div>
            <div class="col-12">
              <input :class="['form-control', {'is-invalid': errorFor('name')}]"
                    :disabled="loading"
                    ref="keywordInput"
                    @keyup.enter="insertKeyword($event)"
                    placeholder="Enter keyword phrase and press enter"
                    type="text">
                <small id="passwordHelpBlock" class="form-text text-muted my-2">Choose keywords from recommendations or enter your own.</small>
              <v-errors :errors="errorFor('name')" />
            </div>
          </div>

          <button class="btn btn-primary btn-sm mb-2 w-100 d-blok py-2"
                  @click="loadKeywords"
                  :disabled="loading">
            <i class="fa-solid fa-rotate"></i> Regenerate recommendations based on current keywords
          </button>

          <div class="row">
            <div class="col-12 text-center">
              <div class="cp-spinner cp-round" v-if="loading"></div>
              <table class="tablesaw table-bordered table-striped" v-else>
                <thead>
                <tr>
                  <th></th>
                  <th @click="sortTable('keyword')">
                    Keyword Phrase
                    <span v-if="this.table.sortColumn === 'keyword'">
                      <i class="fas fa-sort-amount-down-alt"
                        aria-hidden="true"
                        v-if="this.table.sortOrder === 'asc'">
                      </i>
                      <i class="fas fa-sort-amount-down"
                        aria-hidden="true" v-else>
                      </i>
                    </span>
                  </th>
                  <th class="text-right" @click="sortTable('monthlySearch')">
                    Monthly Searches
                    <span v-if="this.table.sortColumn === 'monthlySearch'">
                      <i class="fas fa-sort-amount-down-alt"
                        aria-hidden="true"
                        v-if="this.table.sortOrder === 'asc'">
                      </i>
                      <i class="fas fa-sort-amount-down"
                        aria-hidden="true" v-else>
                      </i>
                    </span>
                  </th>
                  <th class="text-right" @click="sortTable('competition')">
                    Competition
                    <span v-if="this.table.sortColumn === 'competition'">
                      <i class="fas fa-sort-amount-down-alt"
                        aria-hidden="true"
                        v-if="this.table.sortOrder === 'asc'">
                      </i>
                      <i class="fas fa-sort-amount-down"
                        aria-hidden="true" v-else>
                      </i>
                    </span>
                  </th>
                  <th class="text-right" @click="sortTable('lowBid')">
                    Low CPC Bid
                    <span v-if="this.table.sortColumn === 'lowBid'">
                      <i class="fas fa-sort-amount-down-alt"
                        aria-hidden="true"
                        v-if="this.table.sortOrder === 'asc'">
                      </i>
                      <i class="fas fa-sort-amount-down"
                        aria-hidden="true" v-else>
                      </i>
                    </span>
                  </th>
                  <th class="text-right" @click="sortTable('highBid')">
                    High CPC Bid
                    <span v-if="this.table.sortColumn === 'highBid'">
                      <i class="fas fa-sort-amount-down-alt"
                        aria-hidden="true"
                        v-if="this.table.sortOrder === 'asc'">
                      </i>
                      <i class="fas fa-sort-amount-down"
                        aria-hidden="true" v-else>
                      </i>
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!loading && !sortedKeywords.length">
                  <td colspan="6">No suggestion found for the keyword combination you provided. Please try again with different set of keywords</td>
                </tr>
                <tr v-for="(keyword, i) in sortedKeywords"
                    :key="'keyword_'+i">
                  <td class="text-center">
                    <input type="checkbox"
                           name="keywords[]"
                           :value="keyword.keyword"
                           v-model="currentKeywords">
                  </td>
                  <td>{{ keyword.keyword }}</td>
                  <td class="text-right">{{ keyword.monthlySearch.toLocaleString() }}</td>
                  <td class="text-right">{{ keyword.competitionIndex }}%</td>
                  <td class="text-right">{{ keyword.lowBid.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
                  <td class="text-right">{{ keyword.highBid.toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"
                  data-dismiss="modal"
                  @click.prevent="confirmKeywords"
                  class="btn btn-dark btn-rounded">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      table: {
        sortColumn: 'monthlySearch',
        sortOrder: 'desc',
      },
      modal: null,
      customerId: '',
      currentKeywords: [],
      keywords: []
    }
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct'
    }),
    sortedKeywords() {
      let c = [...this.keywords];
      if (this.table.sortOrder === 'asc')
        return c.sort((a,b) => a[this.table.sortColumn] - b[this.table.sortColumn]);
      // return c.reverse();
      else
        return c.sort((a,b) => b[this.table.sortColumn] - a[this.table.sortColumn]);
      // return c;
    },
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('google-keywords-idea-modal'))
  },
  methods: {
    sortTable(col) {
      let order = 'asc';

      if (col === this.table.sortColumn) {
        if (this.table.sortOrder === 'asc')
          order = 'desc';
      }

      this.table.sortColumn = col;
      this.table.sortOrder = order;
    },
    show(customerId, currentKeywords) {
      this.customerId = customerId;
      this.currentKeywords = [...currentKeywords.map(k => k.keyword)];

      this.loadKeywords();

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    confirmKeywords() {
      let confirmKeywords = [];

      this.currentKeywords.forEach(ck => {
        let keyword = this.keywords.find(k => k.keyword === ck);
        let monthlySearch = 0;

        if (keyword) {
          monthlySearch = keyword.monthlySearch;
        }

        confirmKeywords.push({
          keyword: ck,
          monthlySearch: monthlySearch
        })
      })

      this.$emit('selected', confirmKeywords);
      this.hide();
    },
    insertKeyword(event) {
      let keyword = event.target.value;

      if (keyword.length && !this.currentKeywords.includes(keyword))
        this.currentKeywords.push(keyword.toLowerCase());

      this.$refs.keywordInput.value = '';
    },
    deleteKeyword(keyword) {
      this.currentKeywords.splice(this.currentKeywords.findIndex(v => v === keyword), 1);
    },

    duplicateAmazon() { 
      this.currentKeywords.map(keyword => {
        if(keyword.includes('amazon') == false && this.currentKeywords.includes(keyword + ' amazon') == false) {
          this.currentKeywords.push(keyword + ' amazon')
        }
      });
    },

    duplicateOnAmazon() {
      this.currentKeywords.map(keyword => {
        if(keyword.includes('amazon') == false && keyword.includes('on amazon') == false && this.currentKeywords.includes(keyword + ' on amazon') == false) {
          this.currentKeywords.push(keyword + ' on amazon');
        }
      });
    },

    keywordKeyupEvent(event) {
      if (event.key === ',' || event.key === 'Enter') {
        let keyword = event.target.value.replace(',', '');
        if (keyword.length) {
          if (!this.form.keywords.includes(keyword))
            this.form.keywords.push(keyword.toLowerCase());
        }
        this.$refs.keywordInput.value = '';
      }
    },
    
    loadKeywords() {
      this.loading = true;
      /*let previousKeywords = this.currentKeywords;

      this.keywords.forEach(keyword => {
        if (keyword.selected)
          previousKeywords.push(keyword.keyword);
      })*/

      this.keywords = [];

      this.axios.post('/google/suggested-keywords', {
          customerId: this.customerId,
          url: this.amazonProduct.url,
          currentKeywords: this.currentKeywords
        })
        .then(response => {
          this.keywords = response.data.map(keyword => {
            return {
              keyword: keyword.keyword,
              monthlySearch: keyword.monthlySearch,
              competition: keyword.competition,
              lowBid: keyword.low_bid,
              highBid: keyword.high_bid,
              competitionIndex: keyword.competition_index,
            }
          })
        })
        .finally(() => this.loading = false)
    },
  }
}
</script>

<style >

.keyword-tips ul {
    padding: 0px 15px;
    list-style: none;
    margin-top: 15px;
}

</style>