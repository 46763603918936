<template>
  <h6 class="text-black font-weight-bold mb-0 title-line-height mt-3 mb-2">Enter keywords for your Google Ad. Google recommends 10 to 20</h6>

  <div class="mt-4">
    <div class="row mb-2">
      <div class="col-12">
        <div class="badge badge-dark mr-2 mb-2"
             v-for="(key, i) in form.keywords"
             :key="'key_'+i">
          <div class="dropdown d-inline ">
            <button class="btn btn-sm btn-dark"
                    style="padding: 3px"
                    type="button" data-toggle="dropdown" aria-expanded="false">
              {{ key.match == '2' ? 'EXACT' : key.match == '3' ? 'PHRASE' : 'BROAD' }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="changeKeywordMatch(i, '2')"
                 v-if="key.match != '2'">Change to EXACT</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="changeKeywordMatch(i, '3')"
                 v-if="key.match != '3'">Change to PHRASE</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="changeKeywordMatch(i, '4')"
                 v-if="key.match != '4'">Change to BROAD</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="duplicateKeywordAs(i, '2')"
                 v-if="key.match != '2'">Duplicate as EXACT</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="duplicateKeywordAs(i, '3')"
                 v-if="key.match != '3'">Duplicate as PHRASE</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="duplicateKeywordAs(i, '4')"
                 v-if="key.match != '4'">Duplicate as BROAD</a>
              <a class="dropdown-item"
                 href="#"
                 @click.prevent="duplicateKeywordAs(i, '')">Duplicate as both</a>
            </div>
          </div>

          |

          {{ key.keyword }}

          <a href="#"
             @click.prevent="deleteKeyword(i)"
             class="ml-1 text-danger">X</a>
        </div>

<!--        <span class="text-danger" v-if="form.keywords.length < 5"> ({{ 5 - form.keywords.length }} more required)</span>-->
<!--        <span class="text-danger" v-if="form.keywords.length > 20"> (Keywords only supports 20 items)</span>-->
      </div>
    </div>

    <div class="form-group">
      <input :class="['form-control', {'is-invalid': errorFor('name')}]"
             :disabled="loading"
             ref="keywordInput"
             @paste="pasteKeywords"
             @keyup="keywordKeyupEvent($event)"
             placeholder="Enter keyword phrase and press enter"
             type="text">
      <small id="passwordHelpBlock" class="form-text text-muted my-2">Choose keywords from recommendations or enter your own.</small>
      <v-errors :errors="errorFor('name')" />

      <div class="form-check form-check-inline">
        <input class="form-check-input"
               type="radio"
               name="keyword-type"
               v-model="keywordMatch"
               id="Exact"
               value="2">
        <label class="form-check-label"
               for="Exact">Exact</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input"
               type="radio"
               v-model="keywordMatch"
               name="keyword-type"
               id="Phrase"
               value="3">
        <label class="form-check-label"
               for="Phrase">Phrase</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input"
               type="radio"
               name="keyword-type"
               id="Broad"
               v-model="keywordMatch"
               value="4">
        <label class="form-check-label"
               for="Broad">Broad</label>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-success"
                @click="$refs.googleKeywordModal.show(form.ad_account_id, form.keywords)">Recommend more keywords from Google based on current keywords</button>
      </div>
    </div>
  </div>

  <div class="row mb-2 mt-3">
    <div class="col-12 keyword-tips">
      <ul>
        <li>It is often a good choice to add “amazon” and/or “on amazon” to keywords. For example: “Yoga mat on amazon” or “Garlic press amazon”</li>
        <li>If you want, we can do it for you while keeping the original keywords.</li>
        <li>We would only duplicate and add keywords if the phrase “amazon” or “on amazon” doesn’t already exist in them.</li>
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <button type="button" class="btn btn-md btn-success w-100" @click="duplicateAmazon()">Duplicate & Add “amazon” to all keywords</button>
    </div>
    <div class="col-12 col-md-6">
      <button type="button" class="btn btn-md btn-primary w-100" @click="duplicateOnAmazon()">Duplicate & Add “on amazon” to all keywords</button>
    </div>
  </div>


  <div class="row keyword-tips mt-5">
    <h3 class="mb-3">How to choose good keywords?</h3>
    <ul>
      <li>DO Choose keywords that your customers are likely to use when searching for your product</li>
      <li>DON’T Choose keywords that are too generic. The more specific you are the better the conversion. E.g. use “travel yoga mat” not “yoga”</li>
      <li>DO Use keywords you know are converting from your Amazon advertising campaigns</li>
      <li>DON’T Worry about capitalization</li>
    </ul>
  </div>


  <google-keyword-modal ref="googleKeywordModal"
                        @selected="setNewKeywords" />
</template>

<script>
import GoogleKeywordModal from "@/views/create_campaign/google_wizard/GoogleKeywordModal";
export default {
  props: ['formData', 'errors'],
  components: {GoogleKeywordModal},
  data() {
    return {
      adAccounts: [],
      keywordMatch: '2',
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
    this.checkAmazonSearchTerm();
  },
  beforeUpdate() {
    this.form = this.formData;
    // this.checkAmazonSearchTerm();
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    /*formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }*/
  },
  methods: {
    changeKeywordMatch(index, keywordMatch) {
      this.form.keywords[index].match = keywordMatch;
    },
    duplicateKeywordAs(index, keywordMatch) {
      const keyword = this.form.keywords[index];

      if (keywordMatch === '') {
        const matches = ['2', '3', '4'].filter(m => keyword.match != m);

        matches.forEach(match => {
          this.pushKeyword(keyword.keyword, keyword.monthlySearch);
          this.form.keywords[this.form.keywords.length - 1].match = match;
        });
      } else {
        this.pushKeyword(keyword.keyword, keyword.monthlySearch);
        this.form.keywords[this.form.keywords.length - 1].match = keywordMatch;
      }

    },
    pushKeyword(keywordText, monthlySearch = 0) {
      const keyword = keywordText.toLowerCase().trim();
      // const exists = this.form.keywords.find(k => k.keyword === keyword);

      if (keyword.length) {
        this.form.keywords.push({
          monthlySearch: monthlySearch,
          keyword: keyword,
          match: this.keywordMatch
        });
      }
    },
    pasteKeywords() {
      const pasteText = (event.clipboardData || window.clipboardData).getData("text");

      const indexOfNewLine = pasteText.indexOf("\n");

      if (indexOfNewLine !== -1) {
        const lines = pasteText.split("\n");

        lines.forEach(line => {
          this.checkLineKeyword(line);
        });
      } else {
        this.checkLineKeyword(pasteText);
      }

      setTimeout(() => {
        this.$refs.keywordInput.value = '';
      }, 10)
    },
    checkLineKeyword(line) {
      const keywords = line.split(',');

      keywords.forEach(keyword => {
        this.pushKeyword(keyword);
      });
    },
    checkAmazonSearchTerm() {
      this.form.search_term.forEach(term => {
        if (term !== undefined && term !== '') {
          this.pushKeyword(term);
        }
      })
    },
    deleteKeyword(index) {
      this.form.keywords.splice(index, 1);
      // this.form.keywords.splice(this.form.keywords.findIndex(v => v === keyword), 1);
    },

    duplicateAmazon() {
      this.form.keywords.map(keyword => {
        if (!keyword.keyword.includes('amazon')) {
          this.pushKeyword(keyword.keyword + ' amazon');
        }
      });
    },

    duplicateOnAmazon() {
      this.form.keywords.map(keyword => {
        if (!keyword.keyword.includes('amazon') && !keyword.keyword.includes('on amazon')) {
          this.pushKeyword(keyword.keyword + ' on amazon');
        }
      });
    },
    setNewKeywords(keywords) {

      keywords.forEach(key => {
        const exists = this.form.keywords.find(k => k.keyword === key.keyword);

        if (!exists) {
          this.pushKeyword(key.keyword, key.monthlySearch);
        }
      })
    },
    keywordKeyupEvent(event) {
      if (event.key === ',' || event.key === 'Enter') {
        let keyword = event.target.value.replace(',', '');
        if (keyword.length) {
          this.pushKeyword(keyword)
        }
        this.$refs.keywordInput.value = '';
      }
    }
  }
}
</script>

<style>
.keyword-tips {
  padding: 0px 15px;
}

.row.keyword-tips ul {
  display: block;
  width: 100%;
  list-style: none;
  padding-left: 0;
}
.keyword-tips h3 {
  font-size: 18px;
  color: #313131;
}
.keyword-tips ul li {
  color: #313131ad;
  margin-bottom: 12px;
  line-height: 20px;
}

</style>